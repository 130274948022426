.adminPanel__header {
    width: 100%;
    display: inline-block;
}

.adminPanel__calendar__container {
    width: 50%;
}

.adminPanel__appointments__container {
    width: 50%;
}

@media (max-width: 600px) {
    .adminPanel__calendar__container,
    .adminPanel__appointments__container {
        width: 100%;
    }
}

.create_user_modal {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: #da1a35;
    position: fixed;
    display: flex;
    right: 2rem;
    bottom: 2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 900;
    z-index: 99;
    box-shadow: 5px 5px 5px 5px;
    border: 3px solid black;
    text-align: center;
}

.password_text_input {
    display: flex;
    align-items: center;
}

.create_location_options {
    display: flex;
    flex-direction: column;
}
