.LoginContainer {
    position: relative;
    width: 100%;
    text-align: center;
}

.RangeExample {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 230px;
    margin: 0 auto;
}

.nice-select {
    text-align: center;
}

.calendar__btn__container {
    display: flex;
    justify-content: space-around;
}

.link {
    background-color: #da1a35;
    border: none;
    text-align: center;
    /* width: 150px; */
    height: 40px;
    position: relative;
    font-weight: 100;
    width: 100%;
    font-size: 18px;
    color: #fff;
    border-radius: 7px;
    line-height: 40px;
    cursor: pointer;
    margin: 8px;
}

.rdp-day_selected {
    background-color: #f0f8ff;
    color: #4a90e2;
}
.rdp-day_selected {
    border-radius: 0;
}
.range-start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #4a90e2 !important;
    color: #f0f8ff !important;
}
.range-end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: #4a90e2 !important;
    color: #f0f8ff !important;
}
.today {
    color: red;
}
