.application__container {
    width: 100%;
    height: 100vh;
}

.application__content {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.application__logo {
    padding: 15px;
    justify-content: space-between;
    display: flex;
    height: 80px;
}

@media (max-width: 600px) {
    .application__content {
        flex-direction: column;
    }
}

.application__btn {
    background: #696a6c;
    border-color: transparent;
    height: 35px;
    outline-color: transparent;
    border-radius: 5px;
    color: #fff;
    background-color: #da1a35;
    font-size: 21px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}
