.userPanel__header {
    width: 100%;
    display: inline-block;
}

.userPanel__calendar__container {
    width: 50%;
}

.userPanel__appointments__container {
    width: 50%;
}

@media (max-width: 600px) {
    .userPanel__calendar__container,
    .userPanel__appointments__container {
        width: 100%;
    }
}
