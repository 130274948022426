.input__div {
    position: relative;
    width: 100%;
}

.input__div input {
    background: #696a6c;
    border-color: transparent;
    width: 250px;
    height: 30px;
    outline-color: transparent;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.07);
    font-size: 18px;
    margin: 5px;
}
