.controlPanel__settings {
    margin: auto;
    width: 100%;
    .w-100 {
        width: 100%;
    }
    .w-50 {
        width: 50%;
    }
    .row {
        display: flex;
        justify-content: space-around;
    }
}
.text-center {
    text-align: center;
}

.password_cell {
    display: flex;
    align-items: center;
}

.show_hide_icon {
    cursor: pointer;
    display: flex;
    align-self: center;
    margin-left: 5%;
}
